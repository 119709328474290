type Props = React.SVGProps<SVGSVGElement>

const SailogyLogo = (props: Props) => (
  <svg version="1.1" viewBox="0 0 466 141" width={112} height={34} {...props}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Logo" fill="currentColor">
        <path d="M152.5,86.7 C152.5,86.7 159.97,93.53 168.27,93.53 C171.59,93.53 175.18,92.24 175.18,88.27 C175.18,80.44 145.49,80.71 145.49,60.42 C145.49,48.26 155.73,40.23 168.82,40.23 C183.02,40.23 190.03,47.7 190.03,47.7 L183.2,60.89 C183.2,60.89 176.57,55.08 168.64,55.08 C165.33,55.08 161.64,56.55 161.64,60.24 C161.64,68.54 191.33,67.07 191.33,87.91 C191.33,98.91 182.94,108.38 168.09,108.38 C152.6,108.38 144.03,98.97 144.03,98.97 L152.5,86.7 Z" id="Path" />
        <path d="M223.54,77.67 L224.19,77.67 L224.19,76.84 C224.19,72.84 221.19,71.58 217.19,71.58 C211.19,71.58 204.37,76.1 204.37,76.1 L198.84,64.85 C204.722898,60.8657297 211.655011,58.7151044 218.76,58.67 C232.13,58.67 240.15,65.96 240.15,78.04 L240.15,107.27 L225.57,107.27 L225.57,104.41 C225.571438,103.40704 225.634892,102.405128 225.76,101.41 L225.57,101.41 C225.57,101.41 222.07,108.33 212.11,108.33 C203.26,108.33 195.97,102.42 195.97,93.02 C195.97,79.19 216.17,77.62 223.54,77.62 M216.54,96.15 C220.7,96.15 224.38,91.64 224.38,87.76 L224.38,86.76 L222.91,86.76 C217.84,86.76 212.03,88.23 212.03,92.2 C212.03,94.41 213.41,96.2 216.54,96.2" id="Shape" fillRule="nonzero" />
        <path d="M248.45,59.78 L264.4,59.78 L264.4,107.27 L248.45,107.27 L248.45,59.78 Z M248.64,41.34 L264.22,41.34 L264.22,53.79 L248.64,53.79 L248.64,41.34 Z" id="Shape" fillRule="nonzero" />
        <path d="M273.2,41.34 L289.15,41.34 L289.15,87.63 C289.15,92.14 289.98,93.71 293.58,93.71 C294.58,93.71 295.51,93.62 295.51,93.62 L295.51,107.45 C293.919834,107.632934 292.320649,107.726414 290.72,107.73 C282.23,107.73 273.2,105.73 273.2,89.73 L273.2,41.34 Z" id="Path" />
        <path d="M326.64,58.67 C341.64,58.67 353.64,68.67 353.64,83.67 C353.64,98.67 341.64,108.38 326.74,108.38 C311.84,108.38 299.82,98.51 299.82,83.66 C299.82,68.81 311.82,58.66 326.65,58.66 M326.74,95.08 C332.55,95.08 337.53,90.75 337.53,83.65 C337.53,76.55 332.53,71.94 326.74,71.94 C320.95,71.94 315.96,76.46 315.96,83.65 C315.96,90.84 320.94,95.08 326.74,95.08" id="Shape" fillRule="nonzero" />
        <path d="M381.31,113.35 C387.03,113.35 392.31,111.23 392.31,104.23 L392.31,102.66 C392.31,102.11 392.4,100.91 392.4,100.91 L392.22,100.91 C389.45,104.23 385.85,105.91 380.32,105.91 C366.03,105.91 358.93,94.2 358.93,82.02 C358.93,69.84 365.85,58.69 380.23,58.69 C388.9,58.69 393.14,63.31 393.14,63.31 L393.41,63.31 C393.354252,62.9425398 393.324179,62.5716414 393.32,62.2 L393.32,59.78 L408.24,59.78 L408.24,102.93 C408.24,120.27 394.41,126.63 381.41,126.63 C375.169506,126.571527 369.020474,125.123088 363.41,122.39 L367.93,110.31 C372.117158,112.255063 376.673232,113.278134 381.29,113.31 M392.64,82.19 C392.64,73.8 388.21,71.95 383.41,71.95 C377.79,71.95 374.93,76.01 374.93,81.45 C374.93,87.35 377.7,92.61 384.06,92.61 C388.21,92.61 392.64,90.49 392.64,82.19" id="Shape" fillRule="nonzero" />
        <path d="M420.01,111.6 C421.470418,112.578312 423.150468,113.179556 424.9,113.35 C427.48,113.35 430.9,112.62 432.55,108.56 L433.47,106.34 L413.28,59.78 L431.35,59.78 L438.35,81.64 C439.18,84.22 440.1,89.01 440.1,89.01 L440.29,89.01 C440.29,89.01 441.21,84.49 441.86,81.91 L447.86,59.78 L465.28,59.78 L445.83,112.34 C441.83,122.94 433.57,126.63 426.01,126.63 C422.085509,126.558506 418.256113,125.410034 414.94,123.31 L420.01,111.6 Z" id="Path" />
        <polygon id="Path" points="52.59 40.26 0 140.16 10.71 140.16 52.59 60.6" />
        <polygon id="Path" points="78.25 21.24 15.65 140.16 28.4 140.16 78.25 45.45" />
        <polygon id="Path" points="33.81 140.16 107.58 0 107.58 140.16" />
      </g>
    </g>
  </svg>
)

export default SailogyLogo
