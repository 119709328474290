/* eslint max-len: off */
import { useState } from 'react'
import { useRouter } from 'next/router'

const Content: React.FC = () => {
  const [collapsed, setCollapsed] = useState<boolean>(true)
  const { locale } = useRouter()

  if (locale === 'it') {
    return (
      <>
        <p>
          Non vedi l’ora di mollare gli ormeggi e goderti il mare? Cerchi un
          sito
          per noleggio barche sicuro e facile da usare? Sailogy è la risposta a
          tutte le tue domande. Perché? È semplice: Sailogy è la piattaforma
          online leader nel settore del
          {' '}
          <b>noleggio di barche</b>
          {' '}
          con una flotta di oltre 22,000 imbarcazioni tra
          {' '}
          <b>barche a vela, barche a motore, catamarani e caicchi</b>
          , presente nei porti di 5 continenti per offrire soluzioni di vacanza
          in
          tutte le stagioni dell’anno,
          {' '}
          <b>con Sailogy scegli tra oltre mille destinazioni</b>
          .
        </p>
        {collapsed
          && (
            <a
              onClick={() => setCollapsed(false)}
              role="button"
              tabIndex={-1}
              style={{
                textDecoration: 'underline',
                cursor: 'pointer'
              }}
            >
              Leggi di più
            </a>
          )}
        <div style={{ display: collapsed ? 'none' : 'block' }}>
          <h3>Come affittare una barca con Sailogy?</h3>
          <p>
            Sailogy è il sito per noleggio barche e altre tipologie di
            imbarcazioni che garantisce sicurezza e praticità.
          </p>
          <p>
            Basta navigare tra le pagine di Sailogy per capire che chi ama le
            vacanze in barca a vela qui troverà tutto quello che serve per
            organizzare e realizzare un viaggio in mare perfetto.
          </p>
          <p>
            Sailogy opera da uffici in Germania e Svizzera con un team di 40
            persone che parlano complessivamente nove lingue (italiano, inglese,
            tedesco, francese, spagnolo, russo, croato, albanese e greco).
          </p>
          <p>
            Con pochi click e in poco tempo si arriva a concludere il
            {' '}
            <b>
              noleggio barca a vela con skipper o senza
            </b>
            {' '}
            , sempre a costi accessibili e con le
            più alte garanzie possibili. Il software proprietario permette al
            cliente di cercare fra moltissime barche, destinazioni, e
            ispirazioni,
            effettuando la prenotazione online direttamente sul sito e dividendo
            la quota con tutti gli altri partecipanti al viaggio.
          </p>
          <p>
            Sailogy è un’agenzia indipendente, non collegata a cantieri nautici
            o
            società armatoriali. Questo ci ha permesso di sviluppare e mantenere
            una filosofia customer-obsessed e proporre ai clienti sempre le
            barche
            migliori con imparzialità.
          </p>
          <p>
            Sailogy è un’agenzia indipendente, non collegata a cantieri nautici
            o
            società armatoriali. Questo ci ha permesso di sviluppare e mantenere
            una filosofia customer-obsessed e proporre ai clienti sempre le
            barche
            migliori con imparzialità.
          </p>
          <p>
            Sailogy ha
            {' '}
            <b>la miglior flotta del web</b>
            , per dimensioni e affidabilità.
            Le barche sono di proprietà delle migliori società di charter e sono
            costantemente sottoposte a controlli di qualità. Un team di persone
            dedicate, i Sail Hunter, viaggia per il mondo per scattare foto e
            girare video delle barche, costruendo relazioni personali con gli
            armatori. Sailogy infatti pubblica sul proprio portale solo offerte
            certificate e verificate per garantire all’utente di poter
            realizzare
            il proprio sogno di noleggio barca.
          </p>
          <p>
            Sul sito di Sailogy, ad esempio, si possono noleggiare solamente
            barche, con o senza skipper, che sono gestite da società di charter
            nautico con un “track record” ottimo definito da un algoritmo
            proprietario che, incrociando più fattori di performance delle
            barche,
            i volumi di vendita e le recensioni dei clienti, consente un
            controllo
            in tempo reale della qualità della flotta.
          </p>
          <h3>Noleggio barche last minute</h3>
          <p>
            Un altro servizio importante fornito da Sailogy è la possibilità di
            effettuare prenotazioni o noleggio di barche last minute attraverso
            un
            sito avanzato che mostra solo le barche disponibili con offerte
            sempre
            aggiornate e al migliore prezzo di mercato. I clienti hanno tutta la
            sicurezza ed affidabilità di un servizio garantito. L’utente che si
            collega con noi ha la possibilità di risparmiare tempo nella ricerca
            perché non deve aspettare i preventivi; il preventivo infatti è già
            disponibile online.
          </p>
          <p>
            Sailogy è una garanzia anche per quanto riguarda la
            {' '}
            <b>massima trasparenza</b>
            {' '}
            sui prezzi del noleggio yacht: con questo portale infatti
            non ci sono costi nascosti o sorprese, ma tutti gli extra sono
            quotati
            direttamente sul sito; sia quelli obbligatori (come ad esempio i
            costi
            per le pulizie finali dell’imbarcazione) che quelli opzionali.
          </p>
          <p>
            Alla fine del processo di prenotazione, con Sailogy la conferma è
            istantanea: in modo che una volta trovata la barca perfetta si
            possano
            subito prenotare anche altri prodotti, accessori ma molto
            importanti,
            quali ad esempio i biglietti di aereo o treno per raggiungere il
            porto
            d’imbarco, risparmiando anche in questo caso soldi ed essendo sicuri
            di acquistare le offerte migliori.
          </p>
          <h3>La società di charter nautico a tua disposizione</h3>
          <p>
            E per finire, Sailogy garantisce il servizio di assistenza fornito
            dalla società di charter nautico: grazie all’organizzazione targata
            Sailogy chi parte per le vacanze in barca a vela ha a sua
            disposizione
            un
            {' '}
            <b>team di consulenti dedicati</b>
            , raggiungibili da tutto il mondo,
            formati per consigliare i clienti sulla scelta della migliore barca
            e
            la definizione degli itinerari e per gestire i rapporti con le
            società
            di charter da cui esigono un servizio impeccabile sia durante sia
            post
            vacanza.
          </p>
          <p>
            In particolare, quello che distingue Sailogy dai suoi competitor, è
            la
            presenza di team specializzati che fornisce
            {' '}
            <b>assistenza 7 giorni su 7</b>
            {' '}
            durante la vacanza e si occupa di intermediare con le charter
            company
            per tutte le necessità. Qualsiasi dubbio o domanda vengano in mente
            al
            viaggiatore che ha realizzato il noleggio barca a vela, anche
            durante
            la vacanza, c’è sempre raggiungibile disponibile una linea
            telefonica
            dedicata, da ovunque si trovi l’imbarcazione presa in affitto, alla
            quale risponde personale che parla italiano.
          </p>
          <p>
            Per questi motivi Sailogy è diventato il punto di riferimento di
            mercato sia per i navigatori professionisti sia per i viaggiatori
            meno
            esperti che vogliono trovare la barca perfetta per la propria
            vacanza.
            Grazie a Sailogy il mondo delle barche è oggi accessibile a un vasto
            pubblico di viaggiatori che ha la possibilità di vivere
            un’esperienza
            esclusiva con un budget paragonabile a quella di una vacanza
            tradizionale sulla terraferma.
          </p>
        </div>
      </>
    )
  }
  return (
    <>
      <p>
        Looking forward to setting sail and enjoying the sea? Searching for a
        secure and user-friendly boat rental website? Look no further than
        Sailogy! As the top online platform for boat rentals, Sailogy boasts an
        impressive fleet of over 22,000 vessels, including
        {' '}
        <b>
          sailboats,
          motorboats, catamarans, and traditional Turkish gulets
        </b>
        . With a
        global
        presence in ports across 5 continents, Sailogy offers vacation solutions
        for all seasons. Choose from
        {' '}
        <b>over a thousand destinations</b>
        {' '}
        and embark on
        your dream boating adventure with Sailogy!
      </p>
      {collapsed
        && (
          <a
            onClick={() => setCollapsed(false)}
            role="button"
            tabIndex={-1}
            style={{
              textDecoration: 'underline',
              cursor: 'pointer'
            }}
          >
            Leggi di più
          </a>
        )}
      <div style={{ display: collapsed ? 'none' : 'block' }}>
        <h3>How to rent a boat with Sailogy?</h3>
        <p>
          When it comes to sailing vacations, look no further than Sailogy - the
          leading platform for boat and yacht rentals, ensuring safety and
          convenience. With just a few clicks, you can organize your perfect sea
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          adventure on Sailogy's user-friendly website. Whether you're an avid
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          sailor or a novice, we've got everything you need to plan an
          exceptional voyage on the open waters.
        </p>
        <p>
          Operating from offices in Germany and Switzerland, our dedicated team
          of 40 multilingual experts (Italian, English, German, French, Spanish,
          Russian, Croatian, Albanian, and Greek) is here to assist you
          throughout your journey.
        </p>
        <p>
          Our proprietary software empowers customers to explore an extensive
          selection of boats, destinations, and inspirations, making it easy to
          book your dream sailing trip directly on our platform. We offer both
          skippered and bareboat charters at affordable prices, providing the
          highest level of guarantees for your peace of mind. Plus, when you
          book with us, you can share the cost with fellow travelers, making
          sailing even more accessible.
        </p>
        <p>
          At Sailogy, we take pride in being an independent agency,
          unaffiliated with any shipyards or charter companies. This approach
          enables us to maintain a customer-obsessed philosophy and impartially
          present you with the best boat options available.
        </p>
        <p>
          Our fleet is unparalleled in size and reliability, featuring boats
          from top-notch charter companies that undergo regular quality checks.
          Our dedicated team, the Sail Hunters, travels the world, building
          personal relationships with boat owners and capturing photos and
          videos of the boats to ensure that we only publish certified and
          verified offers on our website, fulfilling your dream of a perfect
          boat rental.
        </p>
        <p>
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          On Sailogy's website, you'll find a wide selection of boats, both with
          and without skippers, all managed by reputable charter companies with
          exceptional track records. Our proprietary algorithm factors in boat
          performance, sales volume, and customer reviews, allowing real-time
          monitoring of fleet quality.
        </p>
        <p>
          Choose Sailogy for your next sailing adventure and experience the best
          that the seas have to offer!
        </p>
        <h3>Last-minute boat rental</h3>
        <p>
          In the world of boating and yacht charters, Sailogy stands out as a
          leading service provider offering a range of essential services to its
          customers. One such crucial service is the ability to make last-minute
          boat reservations or rentals through an advanced website. This
          platform displays only available boats with regularly updated offers
          at the best market prices. Customers can trust in the reliability and
          security of this guaranteed service. By connecting with Sailogy, users
          can save valuable time in the booking process as they no longer need
          to wait for quotes; all quotes are readily available online.
        </p>
        <p>
          Sailogy also ensures complete transparency regarding yacht rental
          prices. With this portal, there are no hidden costs or surprises; all
          extras are clearly quoted on the website, including mandatory items
          like final cleaning costs and optional services.
        </p>
        <p>
          The booking process with Sailogy is swift and hassle-free. Customers
          receive instant confirmation, allowing them to book additional
          products and accessories immediately. For instance, they can secure
          airplane or train tickets to reach the embarkation port, saving money
          and ensuring they get the best deals.
        </p>
        <h3>Your Trusted Yacht Charter Company</h3>
        <p>
          Lastly, Sailogy offers exceptional customer support through its
          dedicated nautical charter team. Whether travelers embark on a sailing
          vacation for the first time or are seasoned sailors, they have access
          to a team of expert consultants available from around the world. These
          consultants provide valuable advice on selecting the perfect boat,
          planning itineraries, and managing interactions with charter
          companies, both during and after the vacation.
        </p>
        <p>
          What sets Sailogy apart from its competitors is the presence of
          specialized teams offering assistance 7 days a week, throughout the
          vacation. They act as intermediaries with charter companies,
          addressing any doubts or questions that may arise. No matter where the
          rented boat is located, there is always a dedicated Italian-speaking
          helpline accessible to travelers.
        </p>
        <p>
          Thanks to these exceptional services, Sailogy has become the go-to
          market leader for both professional sailors and less experienced
          travelers seeking the perfect boat for their vacation. Sailogy opens
          up the world of boating to a wide audience of travelers, offering them
          the chance to enjoy an exclusive experience with a budget comparable
          to a traditional land-based vacation.
        </p>
      </div>
    </>
  )
}

export default Content
