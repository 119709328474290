import React, { useMemo } from 'react'
import promos from 'config/promos'
import Logo from 'components/Logo'
import {
  Navbar, useTranslation, useLocalizedUrl, useLanguage
} from '@sailogy/shipwright'
import { K } from './i18n'

interface Props {
  overlay?: boolean
}


const WebsiteNavbar: React.FC<Props> = ({ overlay }) => {
  const t = useTranslation<K>()
  const localizedUrl = useLocalizedUrl()
  const [language] = useLanguage()
  const menu = useMemo(() => [
    {
      label: t('Find your boat'),
      href: localizedUrl('/search')
    },
    // TEMP
    promos.black_friday ? {
      label: t('Best offers'),
      href: localizedUrl('/search')
    } : {
      label: t('Best offers'),
      menu: [
        {
          label: t('Special offers'),
          href: localizedUrl('/offers_special')
        },
        {
          label: t('Weekend'),
          href: localizedUrl('/offers_weekend')
        },
        {
          label: t('Last minute offers'),
          href: localizedUrl('/offers_lastminute')
        }
      ]
    },
    {
      label: t('Luxury yachts'),
      href: localizedUrl('/search?weeks_count=1&order_by=-final_price&price_range=17750,30000&length_range=20,24')
    },
    {
      label: t('Get inspired'),
      menu: [
        {
          label: t('Blog'),
          href: localizedUrl('/blog')
        },
        {
          label: t('Magazine'),
          href: localizedUrl('/magazine')
        },
        {
          label: t('Plan a vacation'),
          href: localizedUrl('/wizard')
        }
        // ...(language === 'de' ? [
        //   {
        //     label: t('Trip reports'),
        //     href: localizedUrl('/insider_report'),
        //   },
        //   {
        //     label: t('Insider info'),
        //     href: localizedUrl('/insider'),
        //   },
        // ] : [])
      ]
    }
  ], [language, localizedUrl])

  return <Navbar logo={Logo} menu={menu} overlay={overlay} />
}

export default React.memo(WebsiteNavbar)
