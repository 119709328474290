import React, { useMemo } from 'react'
import Head from 'next/head'
import Script from 'next/script'
import company from 'config/company.json'
import languages from 'config/languages.json'
import {
  useLanguage, useNavigationContext, useTranslation, localizeUrl, imgixUrl
} from '@sailogy/shipwright'
import { WithContext } from 'schema-dts'
import { K } from './i18n'

const languageKeys = Object.keys(languages)

interface Props {
  title: string
  description?: string
  image?: string
  noIndex?: boolean
  cleanTitle?: boolean
  schema?: WithContext<any>
}


const PageHead: React.FC<Props> = ({
  title, description: customDescription, image,
  noIndex, cleanTitle = false, schema
}) => {
  const { path, vars } = useNavigationContext()
  const t = useTranslation<K>()
  const [language] = useLanguage()
  const siteTitle = t('%(SITE_NAME)s - a boat Rental & Yacht Charters global web site', { SITE_NAME: company.name })
  const description = customDescription || t('%(SITE_NAME)s is your first online booking portal for sailing vacations. Only certified boats! Select an amazing sailing destination and reserve your boat for 9€', { SITE_NAME: company.name })
  const imagePath = imgixUrl(image || 'https://sailogy-pictures-public.imgix.net/images/bg/hendrik-cornelissen-b_oRl_WOMVM-unsplash.jpg', {
    w: 1200,
    h: 1200,
    fit: 'crop',
    crop: 'entropy',
    mark: encodeURIComponent(`https://public-2996.kxcdn.com/responsive/img/watermark/${company.slug}-white.png`),
    markalign: 'middle,center',
    markscale: 70
  })
  const canonicalUrl = useMemo(() => (
    (url) => (url ? `${company.website}${url}` : '')
  )(localizeUrl(language, path, vars)), [language, path, vars])
  // Do not list unavailable slugs
  const alternateUrls: string[][] = useMemo(() => {
    try {
      return languageKeys.reduce((acc, lang) => (
        (url) => (url ? [...acc, [lang, `${company.website}${url}`]] : acc)
      )(localizeUrl(lang, path, vars)), (
        (url) => (url ? [['x-default', `${company.website}${url}`]] : [])
      )(localizeUrl(languageKeys[0], path, vars)))
    } catch {
      return []
    }
  }, [path, vars])

  return (
    <>
      <Head>
        <title>{cleanTitle ? title : `${title} | ${company.name}`}</title>
        <meta name="description" content={description} />

        <link rel="canonical" href={canonicalUrl} />
        {alternateUrls.map(([lang, url]) => (
          <link key={lang} rel="alternate" hrefLang={lang} href={url} />
        ))}
        {noIndex && <meta name="robots" content="noindex" />}

        <meta property="og:type" content="website" />
        <meta property="og:site_name" content={siteTitle} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content={canonicalUrl} />
        <meta property="og:image" content={imagePath} />

        <meta property="twitter:site" content={siteTitle} />
        <meta property="twitter:title" content={title} />
        <meta property="twitter:description" content={description} />
        <meta property="twitter:url" content={canonicalUrl} />
        <meta property="twitter:image" content={imagePath} />

        {schema && (
        // eslint-disable-next-line react/no-danger
        <script id="structured-data" type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(schema) }} />
        )}
      </Head>

      {process.env.GTM_CONTAINER_ID && (
      <Script id="gtm" strategy="afterInteractive">
        {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${process.env.GTM_CONTAINER_ID}');`}
      </Script>
      )}
    </>
  )
}

export default React.memo(PageHead)
