import { dateIsAfter } from '@sailogy/shipwright'

const now = Date.now()

// Rome timezone: summertime +02:00, wintertime +01:00
const promos: Record<string, boolean> = {
  // safe_sailing: dateIsAfter(now, '2020-07-28T00:00:00+01:00'), // till Jan 2021
  black_friday: dateIsAfter(now, '2024-11-25T00:00:00+01:00') && dateIsAfter('2024-12-03T00:00:00+01:00', now)
}

export default promos
